import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Attendant, AttendantWithCompany, JobsData } from '@data/attendant/attendant.model';
import { JobGeneral } from '@data/job-general/job-general.model';
import { JobGeneralQuery } from '@data/job-general/job-general.query';
import { JobGeneralActions } from '@data/job-general/job-general.actions';
import { IonosFacadeService } from '@core/services/upload/ionos-facade.service';
import { DocType } from '@data/file/file.model';
import { CompanyService } from '@data/company/company.service';
import { Company } from '@data/company/company.model';
import { JobDialogComponent, JobInput } from '../job-dialog/job-dialog.component';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { BRANCHES } from '@core/constants/match/branches';
import { COMPANY_SIZE, COMPANY_SIZE_AMOUNT } from '@core/constants/company/size';
import {
  AllowedBoothData,
  AllowedCompanyData,
  AllowedJobData,
  MatchingSession,
} from '@data/session/session.model';
import { CompanyForm } from '../../form/company/company.form';
import { AttendantService } from '@data/attendant/attendant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendantActions } from '@data/attendant/attendant.actions';
import { CompanyActions } from '@data/company/company.actions';
import { cloneDeep } from 'lodash';
import { AttendantStore } from '@data/attendant/attendant.store';
import { ConfirmDialogComponent } from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { tap } from 'rxjs/operators';
import { CompanyStore } from '@data/company/company.store';
import { compare } from '@shared/util/array.util';

export interface CompanyDialogData {
  title: string;
  message: string;
  attendant?: AttendantWithCompany;
  edit?: boolean;
  public?: boolean;
  local?: boolean;
  session: MatchingSession;
  email?: string;
}

@Component({
  selector: 'recrewt-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss'],
})
export class CompanyDialogComponent implements OnInit, OnDestroy {
  form!: CompanyForm;

  selectedJobsDataWithId: JobInput[] = [];

  allJobsDataWithId: JobInput[] = [];

  loading$: Observable<boolean> = of(true);

  edit: boolean = true;

  public: boolean = true;

  local: boolean = false;

  jobs$: Observable<JobGeneral[]> = of([]);

  courses$: Observable<JobGeneral[]> = of([]);

  logoUrl: string | null = null;

  privacyAccepted: boolean = false;

  processingAccepted: boolean = false;

  syncing = false;

  protected readonly BRANCHES = BRANCHES;

  protected readonly COMPANY_SIZE = COMPANY_SIZE;

  protected readonly COMPANY_SIZE_AMOUNT = COMPANY_SIZE_AMOUNT;

  protected readonly AllowedBoothData = AllowedBoothData;

  protected readonly AllowedCompanyData = AllowedCompanyData;

  protected readonly AllowedJobData = AllowedJobData;

  private oldSyncId: {
    interval: number;
    aid: string;
    cid: string;
  } | null = null;

  private syncId: {
    interval: number;
    aid: string;
    cid: string;
  } | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CompanyDialogComponent>,
    public companyService: CompanyService,
    public companyStore: CompanyStore,
    public attendantService: AttendantService,
    public attendantStore: AttendantStore,
    private jobGeneralQuery: JobGeneralQuery,
    private fileService: IonosFacadeService,
    private jobDialog: DialogService<JobDialogComponent, JobInput>,
    private confirmDialog: DialogService<ConfirmDialogComponent, boolean>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: CompanyDialogData,
  ) {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.stopSync();
      } else {
        if (this.oldSyncId) {
          this.startSync(this.oldSyncId.aid, this.oldSyncId.cid);
        }
      }
    });
  }

  get companyFormGroup() {
    return this.form.form.get('company')! as UntypedFormGroup;
  }

  get companyDataFormGroup() {
    return this.companyFormGroup.get('companyData')! as UntypedFormGroup;
  }

  get jobsData() {
    let jobsData: JobsData = {};
    this.allJobsDataWithId.forEach((it: any) => {
      if (!it.id) {
        return;
      }

      const specializations = it.specializations.filter((s: string) => s.split('_')[0] === it.id);
      jobsData = {
        ...jobsData,
        [it.id]: {
          url: it.url,
          desc: it.desc,
          specializations: specializations ?? [],
          educationLevel: it.educationLevel,
          identifiableName: it.identifiableName,
          scholasticData: it.scholasticData,
          matchData: it.matchData,
          salaryUnspecified: it.salaryUnspecified,
          estimatedSalaryEUR: it.estimatedSalaryEUR,
          internshipPossible: it.internshipPossible,
          vacation: it.vacation,
          topics: it.topics,
        },
      } as JobsData;
    });

    return jobsData;
  }

  get jobIds() {
    return this.selectedJobsDataWithId.map((it: any) => it.id);
  }

  get disableSubmit() {
    return this.public && !this.edit && !(this.privacyAccepted && this.processingAccepted);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(): void {
    if (!this.syncId) {
      return;
    }
    this.update(this.syncId.aid, this.syncId.cid);
    this.stopSync();
  }

  sizeMapping = (id: number) => COMPANY_SIZE[id];

  options: (name: string) => Observable<(Company | null)[] | undefined> = (name: string) => {
    if (this.public || this.local) {
      return of([]);
    }
    return this.companyService
      .getByName(name)
      .pipe(tap((companies) => this.companyStore.upsertMany(companies ?? [])));
  };

  value = (object: any) => {
    return typeof object === 'object'
      ? object.companyData?.name ?? '?'
      : '+ Neuen Messeteilnehmer anlegen';
  };

  jobGeneralById = (jobs?: JobGeneral[], id?: string) => jobs?.find((it) => it.id === id);

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('70vw');

    JobGeneralActions.loadJobGeneral();
    this.loading$ = this.jobGeneralQuery.selectLoading();
    this.jobs$ = this.jobGeneralQuery.generalJobs$.pipe(
      map((it) => it.sort((a, b) => compare(a.id, b.id, true))),
    );
    this.courses$ = this.jobGeneralQuery.courses$;
    this.edit = this.data.edit ?? false;
    this.public = this.data.public ?? false;
    this.local = this.data.local ?? false;

    this.initForm();

    const companyId = this.data.attendant?.attendant.importedCompany;
    if (companyId) {
      this.loadLogo(companyId);
    }
  }

  ngOnDestroy() {
    this.stopSync();
  }

  initForm(): void {
    this.form = new CompanyForm(
      this.formBuilder,
      this.data.session.allowJobData,
      this.data.session.allowCompanyData,
    );

    if (!this.data.attendant) {
      return;
    }

    this.jobGeneralQuery.selectWhenLoaded$.subscribe(() => {
      const { attendant, company } = this.data.attendant!;
      this.loadExistingProfile(attendant, company);
    });
  }

  deleteJob(index: number) {
    this.confirmDialog.openWithCallback({
      type: ConfirmDialogComponent,
      dialogData: {
        title: 'Beruf löschen',
        message: 'Möchten Sie diesen Beruf wirklich löschen?',
        cancelText: 'Abbrechen',
        confirmText: 'Löschen',
        confirmColor: 'warn',
      },
      confirmCallback: (confirm) => {
        if (confirm) this.selectedJobsDataWithId.splice(index, 1);
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.isFormValid()) {
      this.dialogRef.close(this.getFormResults());
    } else {
      this.form.form.markAllAsTouched();
    }
  }

  isFormValid(): boolean {
    return this.form.form.valid;
  }

  getFormResults() {
    const aid = this.syncId?.aid ?? this.activatedRoute.snapshot.queryParamMap.get('a');
    const companyData = this.form.data;
    const jobsData = this.jobsData;

    return {
      aid,
      attendant: {
        ...companyData.attendant,
        jobs: this.jobIds,
      },
      company: {
        companyData: companyData.company?.companyData,
        jobsData,
      },
    };
  }

  editJob(jobs: JobGeneral[], courses: JobGeneral[], currentJobIndex: number) {
    const allJobIds = this.allJobsDataWithId.map((it) => it.id);
    const oldJob = cloneDeep(this.selectedJobsDataWithId[currentJobIndex]);
    if (!this.hasBenefits(oldJob)) {
      const benefits = this.copyBenefits();
      if (oldJob.matchData && benefits.matchData) {
        oldJob.matchData.general = {
          ...oldJob.matchData!.general,
          benefits: benefits.matchData!.general.benefits,
        };
        oldJob.matchData.additionalData = {
          ...oldJob.matchData.additionalData,
          benefits: benefits.matchData!.additionalData!.benefits!,
        };
      }
    }

    this.jobDialog.openWithCallback({
      type: JobDialogComponent,
      dialogData: {
        courses,
        jobs: jobs.filter((it) => it.id === oldJob.id || !allJobIds.includes(it.id)),
        allJobs: jobs,
        currentJob: oldJob,
        existingJobs: this.allJobsDataWithId,
        session: this.data.session,
      },
      confirmCallback: (job: JobInput | undefined) => {
        if (!job) {
          return;
        }
        this.selectedJobsDataWithId[currentJobIndex] = job;
        this.allJobsDataWithId = this.allJobsDataWithId.map((it) =>
          it.id === oldJob.id ? job : it,
        );
      },
    });
  }

  addJob(jobs: JobGeneral[], courses: JobGeneral[]) {
    const allJobIds = this.selectedJobsDataWithId.map((it) => it.id);
    const lastBenefits = this.copyBenefits();
    this.jobDialog.openWithCallback({
      type: JobDialogComponent,
      config: { minWidth: '370px' },
      dialogData: {
        jobs: jobs.filter((it) => !allJobIds.includes(it.id)),
        allJobs: jobs,
        courses,
        currentJob: lastBenefits,
        existingJobs: this.allJobsDataWithId,
        session: this.data.session,
      },
      confirmCallback: (job: JobInput | undefined) => {
        if (!job) {
          return;
        }
        this.selectedJobsDataWithId.push(job);
        this.allJobsDataWithId.push(job);
      },
    });
  }

  importCompany(company?: Company, attendant?: Attendant) {
    this.form.patchValue(company, attendant);
    this.selectedJobsDataWithId = this.getJobsDataWithId(company?.jobsData ?? {}, attendant?.jobs);
    this.allJobsDataWithId = this.getJobsDataWithId(company?.jobsData ?? {});
  }

  importAttendant(attendant: Attendant) {
    this.form.patchValue(undefined, attendant);
  }

  onImportSelected(importObj: Company | string | undefined) {
    if (importObj === undefined || this.local) {
      return;
    }

    if (typeof importObj === 'string') {
      this.createNewProfile(importObj);
    } else if (!this.public) {
      this.createNewProfileFromExistingCompany(importObj);
    }
  }

  deleteCompany(importObj: Company | string | undefined) {
    if (this.public) {
      return;
    }
    if (importObj === undefined || typeof importObj === 'string') {
      return;
    }

    CompanyActions.deleteExternalCompany(importObj.id);
  }

  unlinkCompany() {
    if (this.local || this.public || this.edit) {
      return;
    }
    this.clearLogo();
    this.form.form.get('company')?.reset();
    this.form.form.get('company')?.clearValidators();
    this.stopSync();
  }

  uploadLogo(file: File): void {
    if (this.local) {
      return;
    }

    const id = this.form.form.value.company.id;

    try {
      this.fileService
        .uploadDisplayImage('external-companies', 'company/logo', id, file)
        .subscribe((evt) => {
          if (!evt?.blob) {
            return;
          }

          window.URL.revokeObjectURL(this.logoUrl ?? '');
          this.logoUrl = window.URL.createObjectURL(evt.blob);
        });
    } catch (e) {
      console.error(e);
    }
  }

  clearLogo(): void {
    this.logoUrl = null;
  }

  deleteImage(type: DocType, fileName: string): void {
    const id = this.form.form.value.company.id;
    if (!id) {
      return;
    }

    this.fileService.removeDisplayImage('external-companies', id, type, fileName);
    this.logoUrl = null;
  }

  appendHttps() {
    const url = this.form.form.get('company')?.get('companyData')?.get('url');
    if (url?.value && !url.value.startsWith('http://') && !url.value.startsWith('https://')) {
      url?.setValue(`https://${url.value}`);
      this.cdr.detectChanges();
    }
  }

  private startSync(aid: string, cid: string) {
    if (this.data.attendant?.company?.completed || this.local) {
      return;
    }
    if (this.syncId) {
      return;
    }
    const interval = setInterval(() => {
      this.update(aid, cid);
      this.syncing = true;
      setTimeout(() => (this.syncing = false), 1500);
    }, 60000);
    this.syncId = { interval, aid, cid };
  }

  private stopSync() {
    if (!this.syncId) {
      return;
    }

    console.log('Sync stopped');
    clearInterval(this.syncId.interval);
    this.oldSyncId = this.syncId;
    this.syncId = null;
    this.syncing = false;
  }

  private update(aid: string, cid: string) {
    AttendantActions.updateAttendantPublic(this.data.session.id, aid, {
      ...this.getFormResults().attendant,
      isManuallyAdded: true,
    });

    CompanyActions.updateCompanyPublic(cid, this.getFormResults().company);
  }

  private loadExistingProfile(attendant: Attendant, company: Company | undefined) {
    this.importAttendant(attendant);
    this.importCompany(
      {
        id: attendant.importedCompany ?? company?.id,
        ...company,
      } as Company,
      attendant,
    );
    if (!company) {
      return;
    }
    this.startSync(attendant.id, attendant.importedCompany ?? company.id);
  }

  private createNewProfileFromExistingCompany(importObj: Company) {
    this.importCompany(importObj);
    this.loadLogo(importObj.id);
    this.createAttendant(importObj);
  }

  private createNewProfile(importObj: string) {
    this.companyService
      .create(
        { companyData: { name: importObj }, team: this.data.session.team, completed: false },
        this.public,
      )
      .subscribe((company) => {
        if (!company) {
          return;
        }

        this.importCompany(company);
        this.createAttendant(company);
      });
  }

  private createAttendant(company: Company) {
    this.attendantService
      .createWithForeignId(
        {
          foreignId: this.data.session.id,
          input: { importedCompany: company.id },
        },
        this.public,
      )
      .subscribe((attendant) => {
        this.attendantStore.add(attendant);
        this.importAttendant(attendant);
        this.startSync(attendant.id, company.id);
        this.appendAttendantIdToUrl(attendant);

        if (this.data.email) {
          this.attendantService
            .sendEditLink(attendant.session, attendant.id, this.data.email)
            .subscribe();
        }
      });
  }

  private appendAttendantIdToUrl(attendant: Attendant) {
    this.router.navigate([], {
      queryParams: { a: attendant.id },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  private hasBenefits(job: JobInput) {
    if (!job.matchData) {
      return false;
    }

    const sumBenefits = Object.values(job.matchData.general?.benefits ?? {}).reduce(
      (acc, val) => acc + val,
      0,
    );

    const lenAdditionalData = Object.values(job.matchData.additionalData?.benefits ?? {}).reduce(
      (acc, val) => acc + val.length,
      0,
    );

    return sumBenefits + lenAdditionalData > 0;
  }

  private copyBenefits() {
    const lastMatchData = this.selectedJobsDataWithId.find((it) => this.hasBenefits(it))?.matchData;

    return lastMatchData
      ? {
          matchData: {
            general: { benefits: lastMatchData.general.benefits },
            additionalData: { benefits: lastMatchData.additionalData?.benefits },
          },
        }
      : {};
  }

  private loadLogo(id: string): void {
    if (this.local) {
      return;
    }
    this.fileService
      .loadDisplayImage('external-companies', 'company/logo', id)
      ?.subscribe((blob) => {
        if (!blob || blob.size === 0) {
          return;
        }

        this.logoUrl = window.URL.createObjectURL(blob);
      });
  }

  private getJobsDataWithId(data: JobsData, jobs?: string[]): JobInput[] {
    const jobsDataArray: any[] = [];
    Object.entries(data).forEach(([id, value]) => {
      if (!jobs || jobs.includes(id)) {
        jobsDataArray.push({ id, ...value });
      }
    });

    return jobsDataArray;
  }
}
