import { AbstractForm } from '../abstract-form';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RecrewtValidators } from '@shared/util/forms.util';
import { AllowedCompanyData, AllowedJobData } from '@data/session/session.model';
import { decodeBin, encodeBinary } from '@core/services/match/match-analyser/mapper';
import { Company } from '@data/company/company.model';
import { Attendant } from '@data/attendant/attendant.model';
import { BRANCHES } from '@core/constants/match/branches';
import moment from 'moment/moment';
import { ChipEditor } from '@shared/components/chip-editor/chip-editor.component';

export class CompanyForm extends AbstractForm<{
  company: Partial<Company>;
  attendant: Partial<Attendant>;
}> {
  constructor(
    fb: UntypedFormBuilder,
    allowJobData: AllowedJobData[] | undefined,
    allowedCompanyData: AllowedCompanyData[] | undefined,
  ) {
    super(undefined, fb, { allowJobData, allowedCompanyData });
  }

  harvest(formData: any) {
    const branches = encodeBinary(formData.company.companyData.branches?.predefined, BRANCHES);
    const { attendant, company } = this.form.value;
    return {
      attendant: {
        boothNr: attendant.boothNr,
        fromDate: attendant.fromDate ? moment(attendant.fromDate).toDate() : undefined,
        toDate: attendant.toDate ? moment(attendant.toDate).toDate() : undefined,
        importedCompany: company.id,
      },
      company: {
        companyData: {
          ...company.companyData,
          branches,
        },
      },
    };
  }

  patchValue(company?: Company, attendant?: Attendant) {
    if (company) {
      const { branches, branch } = company.companyData;
      const b = branches ? decodeBin(branches, BRANCHES) : branch != null ? [BRANCHES[branch]] : [];
      const patchedCompany = {
        id: company.id,
        companyData: {
          ...company.companyData,
          branches: { custom: [], predefined: b },
        },
      };
      this.form.get('company')?.patchValue(patchedCompany);
    }

    if (attendant) {
      this.form.get('attendant')?.patchValue(attendant);
    }
  }

  protected buildForm(company: undefined, fb: UntypedFormBuilder, config?: any): UntypedFormGroup {
    return fb.group({
      company: fb.group({
        id: [null, [Validators.required]],
        companyData: fb.group({
          name: ['', [Validators.required]],
          url: [
            '',
            [
              RecrewtValidators.URL,
              RecrewtValidators.requiredIf(
                !!config?.allowedCompanyData?.includes(AllowedCompanyData.URL),
              ),
            ],
          ],
          branches: [
            { custom: [], predefined: [] },
            [
              ChipEditor.requiredIf(
                !!config?.allowedCompanyData?.includes(AllowedCompanyData.BRANCH),
              ),
            ],
          ],
          branchDesc: [null],
          branch: [null],
          location: [
            null,
            [
              RecrewtValidators.fromList(),
              RecrewtValidators.requiredIf(
                !!config?.allowedCompanyData?.includes(AllowedCompanyData.LOCATION),
              ),
            ],
          ],
          size: [
            null,
            [
              RecrewtValidators.requiredIf(
                !!config?.allowedCompanyData?.includes(AllowedCompanyData.SIZE),
              ),
            ],
          ],
          contact: fb.group({
            name: [
              null,
              [
                RecrewtValidators.requiredIf(
                  !!config?.allowedCompanyData?.includes(AllowedCompanyData.CONTACT),
                ),
              ],
            ],
            email: [
              null,
              [
                Validators.email,
                RecrewtValidators.requiredIf(
                  !!config?.allowedCompanyData?.includes(AllowedCompanyData.CONTACT),
                ),
              ],
            ],
            registrationEmail: [
              null,
              [
                Validators.email,
                RecrewtValidators.requiredIf(
                  !!config?.allowedCompanyData?.includes(AllowedCompanyData.CONTACT),
                ),
              ],
            ],
            phone: [],
          }),
          jobContact: fb.group({
            name: [
              null,
              [
                RecrewtValidators.requiredIf(
                  !!config?.allowJobData?.includes(AllowedJobData.CONTACT),
                ),
              ],
            ],
            email: [
              null,
              [
                Validators.email,
                RecrewtValidators.requiredIf(
                  !!config?.allowJobData?.includes(AllowedJobData.CONTACT),
                ),
              ],
            ],
            phone: [],
          }),
          theme: fb.group({
            primary: ['', [RecrewtValidators.HEX_COLOR]],
            secondary: ['', [RecrewtValidators.HEX_COLOR]],
          }),
        }),
      }),
      attendant: fb.group({
        boothNr: [''],
        fromDate: [],
        toDate: [],
      }),
    });
  }
}
