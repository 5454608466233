import { DateRangeFilter, Filter, ListFilter, StringFilter } from '@data/filter/filter';
import { Participant } from '@data/participant/participant.model';

export module ParticipantsFilter {
  export function createInitialUsersFilterState(state: any[]): Filter<any, any>[] {
    return [
      new StringFilter<Participant>(
        'Name',
        (it) => it.name,
        state[0]?.isActive,
        state[0]?.value,
        state[0]?.options,
      ),
      new ListFilter<Participant, boolean>(
        [true, false],
        'Fragebogen abgeschlossen',
        (p) => !!p.questionnaireCompleted,
        (it) => 'COMMON.' + (it ? 'yes' : 'no'),
        state[1]?.isActive,
        state[1]?.value,
        state[1]?.options,
      ),
      new ListFilter<Participant, boolean>(
        [true, false],
        'Gematcht über',
        (p) => p.isWebUser,
        (it) => (it ? 'Web' : 'App'),
        state[2]?.isActive,
        state[2]?.value,
        state[2]?.options,
      ),
      new DateRangeFilter<Participant>(
        'von...  bis...',
        (it) => it.createdAt ?? new Date(),
        state[3]?.isActive,
        state[3]?.value,
        state[3]?.options,
      ),
    ];
  }
}
