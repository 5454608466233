<ng-container *recrewtLoader="loading$ | async">
  <ng-container *ngIf="jobs$ | async as jobs">
    <ng-container *ngIf="courses$ | async as courses">
      <div mat-dialog-title>
        <h1>{{ data.title | translate }}</h1>
        <p
          *ngIf="!data.attendant?.company?.completed && !local"
          class="mat-body-2 on-surface-medium no-pad m-b-2"
          fxLayoutAlign="start center"
        >
          <mat-spinner *ngIf="syncing" class="m-r-1 green" diameter="24"></mat-spinner>
          <mat-icon *ngIf="!syncing" class="m-r-1" style="color: green">check</mat-icon> Ihre
          Eingaben werden zwischengespeichert
        </p>
      </div>
      <div mat-dialog-content>
        <div *ngIf="public" class="p-v-1 p-h-2 accent-bg-low b-rad-1 m-b-3">
          <p class="no-pad mat-body-2" ngClass.lt-md="mat-caption">
            Bei Fragen wenden Sie sich gerne jederzeit an unser Crew Mitglied Bianca Kujath.<br />
            E-Mail:
            <a href="mailto:bianca.kujath@recrewt.de" tabindex="-1">bianca.kujath@recrewt.de</a
            ><br />
            Tel: <a href="tel:+49 160 2582637" tabindex="-1">+49 160 2582637</a>
          </p>
        </div>
        <div
          *ngIf="form.form.value.company?.id && !local"
          class="p-v-2"
          fxLayoutAlign="center start"
        >
          <recrewt-image-selector
            (fileDeleted)="deleteImage('company/logo', 'logo')"
            (fileSelected)="uploadLogo($event)"
            [imageUrl]="logoUrl"
            appearance="circle"
            icon="photo"
          ></recrewt-image-selector>
        </div>
        <form (submit)="submit()" [formGroup]="form.form" class="p-v-1" fxLayout="column">
          <h2>{{ data.message | translate }}</h2>

          <div fxFlex="grow" fxLayoutAlign="start baseline" fxLayoutGap="16px">
            <ng-container formGroupName="company">
              <mat-form-field
                *ngIf="edit || form.form.value.company.id"
                appearance="outline"
                class="block width-match-parent"
                formGroupName="companyData"
              >
                <input autocomplete="off" formControlName="name" matInput takeFocus />
                <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
                <mat-label>{{
                  'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.COLUMN_TITLES.name' | translate
                }}</mat-label>
              </mat-form-field>
            </ng-container>

            <recrewt-import-input
              (click)="$event.stopPropagation()"
              (delete)="deleteCompany($event)"
              (optionSelected)="onImportSelected($event)"
              *ngIf="!edit && !form.form.value.company.id"
              [error]="'COMMON.INPUT_ERRORS.empty'"
              [formCtrlName]="'name'"
              [form]="companyFormGroup!"
              [getOptions]="options"
              [getValue]="value"
              [label]="
                'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.COLUMN_TITLES.' +
                (public ? 'name' : 'query')
              "
              [nestedGroupName]="'companyData'"
              [showDelete]="!public"
              class="width-match-parent"
            ></recrewt-import-input>

            <button
              (click)="unlinkCompany()"
              *ngIf="!edit && !public && !local"
              [disabled]="!form.form.value.company.id"
              mat-icon-button
              title="Clear"
            >
              <mat-icon class="on-surface-medium">link_off</mat-icon>
            </button>
          </div>
          <ng-container *ngIf="form.form.value.company.id">
            <section
              *allowInput="AllowedBoothData.BOOTH_NR; values: data.session.allowBoothData"
              formGroupName="attendant"
              fxLayout="column"
            >
              <h3>{{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.attendant' | translate }}</h3>
              <mat-form-field appearance="outline" class="width-match-parent m-b-2">
                <input autocomplete="off" formControlName="boothNr" matInput />
                <mat-label>{{
                  'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.COLUMN_TITLES.booth' | translate
                }}</mat-label>
                <mat-hint>{{
                  'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.booth_hint' | translate
                }}</mat-hint>
              </mat-form-field>
            </section>
            <section
              *ngIf="data.session.allowCompanyData?.length"
              formGroupName="company"
              fxLayout="column"
            >
              <h3>
                {{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.company' | translate }}
              </h3>
              <h4>{{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.general' | translate }}</h4>
              <h5
                *allowInput="AllowedCompanyData.BRANCH; values: data.session.allowCompanyData"
                class="no-pad on-surface-medium"
              >
                {{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.branch' | translate }}*
              </h5>
              <div formGroupName="companyData" fxLayout="column" fxLayoutGap="16px">
                <div fxFlex="grow" fxLayout="row" fxLayout.lt-lg="column">
                  <recrewt-chip-editor
                    *allowInput="AllowedCompanyData.BRANCH; values: data.session.allowCompanyData"
                    [predefinedOnly]="true"
                    [predefined]="BRANCHES"
                    class="p-b-2"
                    formControlName="branches"
                    fxFlex="grow"
                  ></recrewt-chip-editor>
                  <mat-form-field
                    *allowInput="AllowedCompanyData.BRANCH; values: data.session.allowCompanyData"
                    appearance="outline"
                    fxFlex="grow"
                  >
                    <input #input formControlName="branchDesc" matInput maxlength="30" />
                    <mat-hint class="mat-caption" fxLayoutAlign="end end"
                      >{{ input.value.length || 0 }}/30</mat-hint
                    >
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.branch_desc' | translate
                    }}</mat-label>
                  </mat-form-field>
                </div>
                <recrewt-select
                  *allowInput="AllowedCompanyData.SIZE; values: data.session.allowCompanyData"
                  [displayWith]="sizeMapping"
                  [matStyle]="true"
                  [optionTpl]="companySizeTpl"
                  [options]="COMPANY_SIZE.length | iterable"
                  [parentFormGroup]="companyDataFormGroup"
                  [require]="true"
                  error="COMMON.INPUT_ERRORS.select_from_list"
                  formControlName="size"
                  fxFlex="100"
                  placeholder="SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.size"
                ></recrewt-select>
                <ng-template #companySizeTpl let-option>
                  <div fxLayout="column">
                    <p class="no-pad">{{ option | fn: sizeMapping | translate }}</p>
                    <p class="mat-hint no-pad">
                      {{ COMPANY_SIZE_AMOUNT[option] }}{{ ' ' }} Mitarbeiter
                    </p>
                  </div>
                </ng-template>
              </div>

              <div
                *allowInput="AllowedCompanyData.LOCATION; values: data.session.allowCompanyData"
                [formGroup]="companyDataFormGroup"
              >
                <recrewt-location-selector
                  [required]="true"
                  error="COMMON.INPUT_ERRORS.select_from_list"
                  formControlName="location"
                  fxFlex="grow"
                  label="SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.location"
                ></recrewt-location-selector>
              </div>

              <mat-form-field
                *allowInput="AllowedCompanyData.URL; values: data.session.allowCompanyData"
                appearance="outline"
                class="width-match-parent"
                formGroupName="companyData"
              >
                <input (blur)="appendHttps()" formControlName="url" matInput type="url" />
                <mat-error>{{ 'COMMON.INPUT_ERRORS.url' | translate }}</mat-error>
                <mat-label>{{
                  'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.company_url' | translate
                }}</mat-label>
              </mat-form-field>

              <div
                *allowInput="AllowedCompanyData.THEME; values: data.session.allowCompanyData"
                formGroupName="companyData"
                fxLayout.lt-md="column"
                fxLayoutGap.gt-sm="16px"
              >
                <mat-form-field appearance="outline" formGroupName="theme" fxFlex="grow">
                  <input formControlName="primary" matInput type="color" />
                  <mat-error>{{ 'COMMON.INPUT_ERRORS.hex_color' | translate }}</mat-error>
                  <mat-label>{{
                    'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.primary_color' | translate
                  }}</mat-label>
                </mat-form-field>
                <mat-form-field appearance="outline" formGroupName="theme" fxFlex="grow">
                  <input formControlName="secondary" matInput type="color" />
                  <mat-error>{{ 'COMMON.INPUT_ERRORS.hex_color' | translate }}</mat-error>
                  <mat-label>{{
                    'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.secondary_color' | translate
                  }}</mat-label>
                </mat-form-field>
              </div>

              <div *allowInput="AllowedCompanyData.CONTACT; values: data.session.allowCompanyData">
                <h4>{{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.contact' | translate }}</h4>
                <div formGroupName="companyData" fxLayout.lt-md="column" fxLayoutGap.gt-sm="16px">
                  <mat-form-field appearance="outline" formGroupName="contact" fxFlex="grow">
                    <input formControlName="name" matInput />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.contact_name' | translate
                    }}</mat-label>
                    <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" formGroupName="contact" fxFlex="grow">
                    <input formControlName="phone" matInput />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.contact_phone' | translate
                    }}</mat-label>
                  </mat-form-field>
                </div>
                <div formGroupName="companyData">
                  <mat-form-field appearance="outline" formGroupName="contact" fxFlex="grow">
                    <input formControlName="email" matInput type="email" />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.contact_email' | translate
                    }}</mat-label>
                    <mat-error>{{ 'COMMON.INPUT_ERRORS.email' | translate }}</mat-error>
                  </mat-form-field>
                </div>
                <div formGroupName="companyData">
                  <mat-form-field
                    appearance="outline"
                    class="m-b-2"
                    formGroupName="contact"
                    fxFlex="grow"
                  >
                    <input formControlName="registrationEmail" matInput type="email" />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.contact_registration_email'
                        | translate
                    }}</mat-label>
                    <mat-hint fxHide.lt-sm>
                      {{
                        'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.contact_registration_email_hint'
                          | translate
                      }}
                    </mat-hint>
                    <mat-error>{{ 'COMMON.INPUT_ERRORS.email' | translate }}</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div *allowInput="AllowedJobData.CONTACT; values: data.session.allowJobData">
                <h4>{{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_contact' | translate }}</h4>
                <div formGroupName="companyData" fxLayout.lt-md="column" fxLayoutGap.gt-sm="16px">
                  <mat-form-field appearance="outline" formGroupName="jobContact" fxFlex="grow">
                    <input formControlName="name" matInput />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_contact_name' | translate
                    }}</mat-label>
                    <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" formGroupName="jobContact" fxFlex="grow">
                    <input formControlName="phone" matInput />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_contact_phone' | translate
                    }}</mat-label>
                  </mat-form-field>
                </div>
                <div formGroupName="companyData">
                  <mat-form-field appearance="outline" formGroupName="jobContact" fxFlex="grow">
                    <input formControlName="email" matInput type="email" />
                    <mat-label>{{
                      'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_contact_email' | translate
                    }}</mat-label>
                    <mat-error>{{ 'COMMON.INPUT_ERRORS.email' | translate }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </section>

            <section *ngIf="!local" formGroupName="company">
              <h3>Ihre Ausbildungsberufe</h3>
              <div class="width-match-parent" fxLayout="column" fxLayoutGap="8px">
                <ng-container
                  *ngFor="let obj of selectedJobsDataWithId; let i = index; let l = last"
                >
                  <div
                    (click)="editJob(jobs, courses, i)"
                    class="pointer hover"
                    fxLayoutAlign="space-between center"
                    matRipple
                  >
                    {{
                      obj.identifiableName ?? (jobs$ | async | fn: jobGeneralById:obj.id)?.name.de
                    }}
                    <div>
                      <button
                        (click)="editJob(jobs, courses, i); $event.stopPropagation()"
                        mat-icon-button
                        type="button"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        (click)="deleteJob(i); $event.stopPropagation()"
                        mat-icon-button
                        type="button"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <mat-divider *ngIf="!l" class="m-b-2"></mat-divider>
                </ng-container>
              </div>

              <button
                (click)="addJob(jobs, courses)"
                class="m-v-2"
                color="primary"
                mat-raised-button
                type="button"
              >
                <mat-icon>add</mat-icon>
                {{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.add_job' | translate }}
              </button>
            </section>
          </ng-container>
          <div
            *ngIf="public && !edit && form.form.value.company.id"
            class="m-t-2"
            fxLayout="column"
          >
            <mat-checkbox (change)="privacyAccepted = $event.checked"
              >Ich habe die
              <a routerLink="/info/privacy" target="_blank noreferrer noopener"
                >Datenschutzerklärung</a
              >
              zur Kenntnis genommen
            </mat-checkbox>
            <mat-checkbox (change)="processingAccepted = $event.checked"
              >Ich stimme der Datenverarbeitung durch die RECREWT GmbH für dieses Event zu
            </mat-checkbox>
          </div>
        </form>
        <div fxLayoutAlign="end center" mat-dialog-actions>
          <form [formGroup]="form.form">
            <button (click)="onNoClick()" mat-button>
              {{ 'COMMON.cancel' | translate }}
            </button>
            <button
              (click)="submit()"
              [disabled]="disableSubmit"
              autofocus
              color="accent"
              mat-raised-button
              type="submit"
            >
              {{ (form.form.value.company.id ? 'COMMON.send' : 'COMMON.continue') | translate }}
            </button>
          </form>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
