import { Component, Input, OnInit } from '@angular/core';
import { Filter, FilterOption } from '@data/filter/filter';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'recrewt-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filter?: Filter<any, any>;

  @Input() frmGroup!: UntypedFormGroup;

  @Input() frmArrayName!: string | number | null; // TODO:   @Input() frmArrayName?: string; changed: Was throwing error: Type 'string | undefined' is not assignable to type 'string | number | null'.

  @Input() frmControlName!: string | number | null; //TODO:   @Input() frmControlName?: string | number; changed: was throwing error :  Type 'string |  number | undefined' is not assignable to type 'string | number | null'.

  activeOptions: FilterOption<any, any>[] = [];

  private get formControl() {
    return this.frmGroup?.get([this.frmArrayName ?? '', this.frmControlName ?? '']);
  }

  ngOnInit(): void {
    this.activeOptions =
      this.filter?.options.filter((it) => it.isActive) ?? this.filter?.options ?? [];
    this.activeOptions.forEach((it) => (it.isActive = true));
    this.formControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      this.filter?.setValue(val);
    });
  }

  updateOption(option: FilterOption<any, any>) {
    this.activeOptions.forEach((it) => (it.isActive = false));
    option.isActive = true;
    this.activeOptions = [option];
  }

  setActive(active: boolean) {
    if (!this.filter) return;
    this.filter.isActive = active;
    this.formControl?.updateValueAndValidity();
    if (active) {
      this.formControl?.markAsUntouched();
    }
  }
}
