import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { Participant } from './participant.model';
import { ParticipantActions } from '@data/participant/participant.actions';
import { MatchingSession } from '@data/session/session.model';

export function dateFromObjectId(objectId: string) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export interface ParticipantState
  extends EntityState<Participant, Participant['_id']>,
    ActiveState<Participant['_id']> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Participant', resettable: true, idKey: '_id' })
export class ParticipantStore extends EntityStore<ParticipantState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor

  addJoiningParticipant(joinedParticipant: Participant, sessionId: MatchingSession['id']) {
    this.add(joinedParticipant);
    ParticipantActions.loadParticipantById(joinedParticipant._id, sessionId);
  }

  override akitaPreAddEntity(newEntity: any): getEntityType<ParticipantState> {
    newEntity.createdAt = dateFromObjectId(newEntity._id);
    return super.akitaPreAddEntity(newEntity);
  }
}
